<template>
  <div v-loading="loading">
    <shared-detail
      v-if="!loading"
      :data="data"
      :detail="{ ...detail, detailType }"
      :tabs="allTabs"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPlaceById } from '@/services/place'
import { getPostById } from '@/services/post'
import { getPlaceTypes } from '@/utils/filters'

import SharedDetail from '@/components/shared/detail'

export default {
  name: 'SharedPageDetail',
  components: {
    SharedDetail
  },
  data() {
    return {
      loading: true,
      data: this.$route.meta.data,
      detail: { name: '' }
    }
  },
  computed: {
    ...mapGetters(['language', 'accountOrg']),
    placeTypes() {
      return getPlaceTypes(false)
    },
    allTabs() {
      let tabs = this.placeTypes.filter((t) => this.data.detail.includes(t.value))
      if (this.accountOrg) {
        const menus = this.accountOrg.menus.split(',')
        tabs = tabs.filter((t) => menus.includes(t.value))
      }
      return tabs.filter((t) => this.data.type !== t.value || !this.detail.parent_id).map((t) => {
        const label = t.children && this.data.type === t.value ? `${t.name} con` : t.name
        return { ...t, label }
      })
    },
    detailType() {
      return this.placeTypes.find((r) => r.value === this.data.type)
    },
    params() {
      return {
        id: this.$route.params.id,
        locale: this.language
      }
    }
  },
  watch: {
    language: 'getDetailInfo'
  },
  beforeMount() {
    this.getDetailInfo()
  },
  methods: {
    getDetailInfo() {
      let request = null
      request = this.data.type === 'post' ? getPostById(this.params) : getPlaceById(this.params)
      this.loading = true
      request.then((response) => {
        this.detail = response
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
