<template>
  <el-dialog
    top="80px"
    width="600px"
    class="package-form"
    :title="!hierarchy ? 'Thêm mới phân hệ' : 'Chỉnh sửa phân hệ'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <el-form-item label="Loại phân hệ" prop="hierarchy_type">
        <el-select v-model="form.hierarchy_type" disabled class="w-100">
          <el-option
            v-for="c in placeTypes"
            :key="c.value"
            :label="c.name"
            :value="c.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Tên phân hệ" prop="name">
        <el-input v-model="form.name" placeholder="Nhập vào tên phân hệ"></el-input>
      </el-form-item>
      <el-form-item label="Trưởng phân hệ" prop="based_id">
        <el-select
          v-model="form.based_id"
          class="w-100"
          filterable
          remote
          :placeholder="`Chọn ${getPlaceType(type)}`"
          :remote-method="searchObjectByType"
          :loading="loading"
          :disabled="callingAPI || !!hierarchy || !!place"
        >
          <el-option
            v-for="p in baseds"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Mô tả phân hệ" prop="description">
        <el-input v-model="form.description" type="textarea" placeholder="Nhập vào mô tả"></el-input>
      </el-form-item>
      <el-row :gutter="12">
        <el-col :span="8">
          <img
            style="width: 100%; height: 80px; margin-bottom: 22px; object-fit: contain;"
            :src="form.image_url || '/default-image.jpeg'"
          >
        </el-col>
        <el-col :span="16">
          <el-form-item label="Link ảnh phân hệ" prop="image_url">
            <el-input
              v-model="form.image_url"
              placeholder="Nhập vào link ảnh"
              :disabled="uploading"
            >
              <el-upload
                slot="suffix"
                accept="image/*"
                list-type="picture"
                action
                :show-file-list="false"
                :http-request="uploadFiles"
              >
                <i :class="uploading ? 'el-icon-loading' : 'el-icon-upload'" style="font-size: 24px; margin-top: 8px; margin-right: 4px;"></i>
              </el-upload>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdateHierarchy">{{ !hierarchy ? 'Tạo' : 'Cập nhật' }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPlaceType } from '@/utils/filters'
import { listPlaces } from '@/services/place'
import { listPosts } from '@/services/post'
import { listTours } from '@/services/tour'
import { createHierarchy, updateHierarchy } from '@/services/hierarchy'
import { createStorage } from '@/services/storage'

export default {
  name: 'UpdateHierarchy',
  props: {
    hierarchy: Object,
    visible: Boolean,
    type: String,
    placeTypes: Array,
    place: Object
  },
  data() {
    return {
      loading: false,
      searchText: '',
      callingAPI: false,
      uploading: false,
      tours: [],
      posts: [],
      places: [],
      form: {
        based_id: this.place ? this.place.id : (this.hierarchy ? this.hierarchy.based_id : ''),
        hierarchy_type: this.type,
        name: this.hierarchy ? this.hierarchy.name : '',
        description: this.hierarchy ? this.hierarchy.description : '',
        image_url: this.hierarchy ? this.hierarchy.image_url : ''
      },
      rules: {
        name: [
          { required: true, message: 'Tên phân hệ không được để trống', trigger: ['blur', 'change'] }
        ],
        based_id: [
          { required: true, message: 'Trưởng phân hệ không được để trống', trigger: ['blur', 'change'] }
        ],
        image_url: [
          {
            pattern: /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
            message: 'Link ảnh không hợp lệ',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    currentType() {
      return this.placeTypes.find((t) => t.value === this.type)
    },
    placeType() {
      return this.currentType.type
    },
    params() {
      return {
        locale: this.language,
        place_types: this.type,
        page: 1,
        per_page: 10,
        search_text: this.searchText
      }
    },
    baseds() {
      if (this.place) {
        return [this.place]
      }
      if (this.hierarchy && this.hierarchy.based_model && this.hierarchy.based_model.id) {
        return [this.hierarchy.based_model]
      }
      if (this.placeType === 'post') {
        return this.posts
      }
      if (this.placeType === 'place') {
        return this.places
      }
      return this.tours
    },
    dataInput() {
      const base = this.baseds.find((b) => b.id === this.form.based_id)
      const data = {
        based_type: this.placeType,
        name: this.form.name,
        based_id: this.form.based_id,
        description: this.form.description,
        image_url: this.form.image_url,
        hierarchy_type: this.type,
        status: base.status,
        locale: this.language
      }
      return data
    }
  },
  beforeMount() {
    if (!this.place && (!this.hierarchy || !this.hierarchy.based_model.id)) {
      this.loadData()
    }
  },
  methods: {
    getPlaceType,
    loadData() {
      this.loading = true
      let request = null
      if (this.placeType === 'tour') {
        request = listTours(this.params)
      } else if (this.placeType === 'post') {
        request = listPosts(this.params)
      } else {
        request = listPlaces(this.params)
      }
      request.then((res) => {
        if (this.placeType === 'tour') {
          this.tours = res.result
        } else if (this.placeType === 'post') {
          this.posts = res.result
        } else {
          this.places = res.result
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    searchObjectByType(text) {
      this.searchText = text
      setTimeout(() => {
        this.loadData()
      }, 100)
    },
    handleUpdateHierarchy() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.hierarchy) {
            createHierarchy(this.dataInput).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Tạo mới phân hệ thành công'
              })
              this.$emit('reload')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          } else {
            updateHierarchy({
              ...this.dataInput,
              id: this.hierarchy.id
            }).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Cập nhật phân hệ thành công'
              })
              this.$emit('reload')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          }
        }
      })
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    },
    uploadFiles(fileInfo) {
      this.uploading = true
      const params = {
        file: fileInfo.file,
        objectId: '',
        objectType: '',
        fileType: 'image',
        locale: this.language
      }
      createStorage(params).then((res) => {
        this.form.image_url = res.data.url
        this.uploading = false
      }).catch(() => {
        this.$notify.error({
          title: 'Thông báo',
          message: 'Không thể upload file: ' + fileInfo.file.name
        })
        this.uploading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
