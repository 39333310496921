<template>
  <div class="shared-detail">
    <el-row
      class="title"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <h3 class="m-0">
        THÔNG TIN CHI TIẾT {{ data.title.toUpperCase() }} {{ detail.parent_id ? 'CON' : '' }}: {{ detail.name.toUpperCase() }}
      </h3>
      <shared-actions
        :data="detail"
        :type="data.type"
        :actions="data.index.actions"
        :table-data="[]"
        :router-data="data"
        @showQR="() => qrVisible = true"
        @reloadPage="() => $router.push({ name: data.name })"
      />
    </el-row>
    <el-row class="shared-tabs">
      <el-tabs v-model="activeType">
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab.value"
          :label="tab.label.toUpperCase()"
          :name="tab.value"
        />
      </el-tabs>
    </el-row>
    <div v-if="detail.id">
      <shared-index
        v-if="currentRouter.type !== data.type"
        :key="currentRouter.type"
        :type="currentRouter.type"
        :titles="currentRouter.index.titles"
        :columns="currentRouter.index.columns"
        :actions="currentRouter.index.actions"
        :header-actions="currentRouter.index.headerActions"
        :filterable="currentRouter.index.headerActions.includes('filter')"
        :detail="{ ...detail, type: data.type }"
        :tab="currentRouter"
      />
      <shared-index
        v-if="currentTabType && currentTabType.children"
        :key="`${currentRouter.type}-children`"
        :type="currentRouter.type"
        :titles="currentRouter.index.titles"
        :columns="currentRouter.index.columns"
        :actions="currentRouter.index.actions"
        :header-actions="currentRouter.index.headerActions"
        :filterable="currentRouter.index.headerActions.includes('filter')"
        :detail="{ ...detail, type: data.type }"
        :children="true"
        :tab="currentRouter"
      />
    </div>
    <div v-else-if="detail.id && currentTabType.type === 'hierarchy'">
      <hierarchy-index
        :key="currentRouter.type"
        :type="data.type"
        :place="detail"
      />
    </div>
    <qrcode-popup
      :visible="qrVisible"
      :row="detail"
      @close="qrVisible = false"
    />
  </div>
</template>

<script>

import { getAccountRouters } from '@/utils/filters'
import SharedIndex from './index'
import HierarchyIndex from '../../views/hierarchy'
import SharedActions from './components/Actions.vue'
import QrcodePopup from '../popups/Qrcode.vue'

export default {
  name: 'SharedDetail',
  components: {
    SharedActions,
    SharedIndex,
    HierarchyIndex,
    QrcodePopup
  },
  props: {
    data: Object,
    detail: Object,
    tabs: Array
  },
  data() {
    return {
      qrVisible: false,
      activeType: this.tabs[0].value
    }
  },
  computed: {
    currentTabType() {
      return this.tabs.find((r) => r.value === this.activeType)
    },
    currentRouter() {
      return getAccountRouters().find((r) => r.type === this.activeType)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>
